import nosos from "../../assets/nosos.gif";
import EOG from "../../assets/eog.png";
import TIAA from "../../assets/TIAA.png";
import UTD from "../../assets/utd.png";
import ANM from "../../assets/a&m.jpeg";
import RMP from "../../assets/rmp.png";
import ALLIN from "../../assets/ALLIN.png";

export const projectsData = [
  {
    id: 1,
    image: nosos,
    title: "Nosos (Biometric Disease Detection)",
    category: "web",
    link: "https://devpost.com/software/nosos",
  },
  {
    id: 2,
    image: EOG,
    title: "EOG Resources Analysis & Prediction",
    category: "web",
    link: "https://devpost.com/software/eog-resources-analysis-prediction",
  },
  {
    id: 3,
    image: TIAA,
    title: "TIAA Financial Cents",
    category: "web",
    link: "https://devpost.com/software/financial-cents",
  },
  {
    id: 4,
    image: UTD,
    title: "UTD Building Classifier",
    category: "app",
    link: "https://devpost.com/software/utd-building-classifier",
  },
  {
    id: 5,
    image: ANM,
    title: "ML Food Classifier",
    category: "app",
    link: "https://devpost.com/software/ml-food-classifier",
  },
  {
    id: 6,
    image: RMP,
    title: "UTD RMP Course Class Recommender",
    category: "app",
    link: "https://devpost.com/software/course-class-recommender",
  },
  {
    id: 7,
    image: ALLIN,
    title: "ALLIN NFC - Wireframes",
    category: "design",
    link: "https://www.figma.com/file/obmAfGzNemp5KMmzOwB6qy/ALLIN?node-id=0%3A1&t=KdPzWvhGUO5FcFxL-1",
  },
];

// projects
export const projectsNav = [
  {
    name: "all",
  },
  {
    name: "web",
  },
  {
    name: "app",
  },
  {
    name: "design",
  },
];
