import React, { useState } from "react";
import "./header.css";

const Header = () => {
  /*=============== Change Background Header ===============*/
  window.addEventListener("scroll", function () {
    const header = document.querySelector(".header");
    // when the scroll is higher than 200 viewport height, add the scroll-header class to a tag with the header tag
    if (this.scrollY >= 80) header.classList.add("scroll-header");
    else header.classList.remove("scroll-header");
  });

  /*=============== Toggle Menu ===============*/
  const [Toggle, showMenu] = useState(false);
  const [activeNav, setActiveNav] = useState("#home");

  return (
    <header className='header'>
      <nav className='nav container'>
        <a href='index.html' className='nav__logo'>
          Narendrula
        </a>

        <div className={Toggle ? "nav__menu show-menu" : "nav__menu"}>
          <ul className='nav__list grid'>
            <li className='nav__item'>
              <a
                href='#home'
                onClick={() => setActiveNav("#home")}
                className={
                  activeNav === "#home" ? "nav__link active-link" : "nav__link"
                }
              >
                <i className='uil uil-estate nav__icon'></i> Home
              </a>
            </li>

            <li className='nav__item'>
              <a
                href='#aboutme'
                onClick={() => setActiveNav("#aboutme")}
                className={
                  activeNav === "#aboutme"
                    ? "nav__link active-link"
                    : "nav__link"
                }
              >
                <i className='uil uil-user nav__icon'></i> About Me
              </a>
            </li>

            <li className='nav__item'>
              <a
                href='#myskills'
                onClick={() => setActiveNav("#myskills")}
                className={
                  activeNav === "#myskills"
                    ? "nav__link active-link"
                    : "nav__link"
                }
              >
                <i className='uil uil-file-alt nav__icon'></i> My Skills
              </a>
            </li>

            <li className='nav__item'>
              <a
                href='#featuredprojects'
                onClick={() => setActiveNav("#featuredprojects")}
                className={
                  activeNav === "#featuredprojects"
                    ? "nav__link active-link"
                    : "nav__link"
                }
              >
                <i className='uil uil-briefcase-alt nav__icon'></i> Featured
                Projects
              </a>
            </li>

            <li className='nav__item'>
              <a
                href='#allprojects'
                onClick={() => setActiveNav("#allprojects")}
                className={
                  activeNav === "#allprojects"
                    ? "nav__link active-link"
                    : "nav__link"
                }
              >
                <i className='uil uil-scenery nav__icon'></i> All Projects
              </a>
            </li>

            <li className='nav__item'>
              <a
                href='#contactme'
                onClick={() => setActiveNav("#contactme")}
                className={
                  activeNav === "#contactme"
                    ? "nav__link active-link"
                    : "nav__link"
                }
              >
                <i className='uil uil-message nav__icon'></i> Contact Me
              </a>
            </li>
          </ul>

          <i
            className='uil uil-times nav__close'
            onClick={() => showMenu(!Toggle)}
          ></i>
        </div>

        <div className='nav__toggle' onClick={() => showMenu(!Toggle)}>
          <i className='uil uil-apps'></i>
        </div>
      </nav>
    </header>
  );
};

export default Header;
