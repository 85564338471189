import React from "react";

const Social = () => {
  return (
    <div className='home__social'>
      <a
        href='https://www.linkedin.com/in/nehanth'
        className='home__social-icon'
        target='https://www.linkedin.com/in/nehanth'
      >
        <i className='uil uil-linkedin'></i>
      </a>

      <a
        href='https://github.com/Nehanth'
        className='home__social-icon'
        target='https://github.com/Nehanth'
      >
        <i className='uil uil-github-alt'></i>
      </a>

      <a
        href='https://twitter.com/nehanth_n'
        className='home__social-icon'
        target='https://twitter.com/nehanth_n'
      >
        <i className='uil uil-twitter-alt'></i>
      </a>
    </div>
  );
};

export default Social;
