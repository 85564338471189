import React from "react";
import "./skills.css";
import Languages from "./Languages";
import Framework from "./Framework";
import Technologies from "./Technologies";
import Tools from "./Tools";

const Skills = () => {
  return (
    <section className='skills section' id='myskills'>
      <h2 className='section__title'>Skills</h2>
      <span className='section__subtitle'>My technical level</span>

      <div className='skills__container container grid'>
        <Languages />
        <Framework />
        <Technologies />
        <Tools />
      </div>
    </section>
  );
};

export default Skills;
