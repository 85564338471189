import React from "react";
import "./work.css";
import Works from "./Works";

const Work = () => {
  return (
    <section class='work section' id='allprojects'>
      <h2 class='section__title'>All Projects</h2>
      <span class='section__subtitle'>My Most Recent Work</span>

      <Works />
    </section>
  );
};

export default Work;
