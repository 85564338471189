import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer__container container'>
        <h1 className='footer__title'>Narendrula</h1>

        <div className='footer__social'>
          <a
            href='https://www.linkedin.com/in/nehanthnarendrula/'
            className='footer__social-link'
            target='_blank'
            rel='noreferrer'
          >
            <i className='bx bxl-linkedin'></i>
          </a>

          <a
            href='https://github.com/Nehanth'
            className='footer__social-link'
            target='_blank'
            rel='noreferrer'
          >
            <i className='bx bxl-github'></i>
          </a>

          <a
            href='https://twitter.com/nehanth_n'
            className='footer__social-link'
            target='_blank'
            rel='noreferrer'
          >
            <i className='bx bxl-twitter'></i>
          </a>
        </div>

        <span className='footer__copy'>
          &#169; Portfoilio created by Nehanth Naredrula
        </span>
      </div>
    </footer>
  );
};

export default Footer;
