import React, { useState } from "react";
import "./featured_projects.css";

const Featured_Projects = () => {
  const [toggleState, setToggleState] = useState(0);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <section className='services section' id='featuredprojects'>
      <h2 className='section__title'>Featured Projects</h2>
      <span className='section__subtitle'>
        My Favorite Projects I Have Made So Far{" "}
      </span>

      <div className='services__container container grid'>
        <div className='services__content'>
          <div>
            <i className='uil uil-robot services__icon'></i>
            <h3 className='services__title'>
              Twitter Bot <br /> Bot or Not Detection
            </h3>
          </div>

          <span className='services__button' onClick={() => toggleTab(1)}>
            View More
            <i className='uil uil-arrow-right services__button-icon'></i>
          </span>

          <div
            className={
              toggleState === 1
                ? "services__modal active-modal"
                : "services__modal"
            }
          >
            <div className='services__modal-content'>
              <i
                onClick={() => toggleTab(0)}
                className='uil uil-times services__modal-close'
              ></i>

              <h3 className='services__modal-title'>
                Twitter Bot - Bot or Not Detection
              </h3>
              <p className='services__modal-description'>
                - Developed a Twitter bot (@botornot420 on twitter) that
                utilizes NLP and the Twitter API to predict weather or not the
                tweet is a bot or human.
                <br></br>
                <br></br>- Built & Trained a Multinomial Naive Bayes Model using
                Scikit-learn which yields accuracy of 83% percent
              </p>

              <p className='services__modal-description'>
                <span className='services__button' onClick={() => toggleTab(1)}>
                  <a
                    href='https://github.com/Nehanth/Twitter_COREML_AND_NB_DETECTION'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <div className='service_color'>View Github Repo</div>
                  </a>
                  <i className='uil uil-arrow-right services__button-icon'></i>
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className='services__content'>
          <div>
            <i className='uil uil-dna services__icon'></i>
            <h3 className='services__title'>
              Disease Detection <br /> Through Fingerprints
            </h3>
          </div>

          <span onClick={() => toggleTab(2)} className='services__button'>
            View More
            <i className='uil uil-arrow-right services__button-icon'></i>
          </span>

          <div
            className={
              toggleState === 2
                ? "services__modal active-modal"
                : "services__modal"
            }
          >
            <div className='services__modal-content'>
              <i
                onClick={() => toggleTab(0)}
                className='uil uil-times services__modal-close'
              ></i>

              <h3 className='services__modal-title'>
                Disease Detection Through Fingerprints
              </h3>
              <p className='services__modal-description'>
                People in rural areas and third-world countries do not have
                immediate access to affordable healthcare. Often times people
                will avoid the doctor to save money. This is a problem because
                these genetic diseases are treatable if they're caught in the
                early stages. Our solution streamlines the diagnosis process by
                utilizing machine learning to bring disease detection to your
                fingertips.
              </p>
              <p className='services__modal-description'>
                <span className='services__button' onClick={() => toggleTab(1)}>
                  <a
                    href='https://devpost.com/software/nosos'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <div className='service_color'>View Demo</div>
                  </a>
                  <i className='uil uil-arrow-right services__button-icon'></i>
                </span>
              </p>

              <ul className='services__modal-services grid'>
                <li className='services__modal-service'></li>
              </ul>
            </div>
          </div>
        </div>

        <div className='services__content'>
          <div>
            <i className='uil uil-book-alt services__icon'></i>
            <h3 className='services__title'>
              UT Dallas <br />
              Building Classifer
            </h3>
          </div>

          <span onClick={() => toggleTab(3)} className='services__button'>
            View More
            <i className='uil uil-arrow-right services__button-icon'></i>
          </span>

          <div
            className={
              toggleState === 3
                ? "services__modal active-modal"
                : "services__modal"
            }
          >
            <div className='services__modal-content'>
              <i
                onClick={() => toggleTab(0)}
                className='uil uil-times services__modal-close'
              ></i>

              <h3 className='services__modal-title'>
                UT Dallas Building Classifer
              </h3>
              <p className='services__modal-description'>
                Getting lost on the first day of school is a fear shared by
                many. Incoming college students, freshman or transfer, often
                struggle with locating their classes and finding on-campus
                eateries. And if your university doesn’t provide you with a
                virtual map of its campus, you may be out of luck. Fortunately,
                UTD has provided its students with a campus map. Though, the map
                may seem puzzling due its ambiguity and the mobile UX. Thus, we
                decided to develop an IOS application to bolster the end-to-end
                experience users share with their handheld devices and the 3D
                world around UTD’s campus.
              </p>
              <p className='services__modal-description'>
                <span className='services__button' onClick={() => toggleTab(1)}>
                  <a
                    href='https://devpost.com/software/utd-building-classifier'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <div className='service_color'>View Demo</div>
                  </a>
                  <i className='uil uil-arrow-right services__button-icon'></i>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Featured_Projects;
