import Donut2924 from ".//../../assets/Donut 2924.avif";
import Donut2608 from ".//../../assets//Donut 2608.avif";
import Donut2059 from ".//../../assets/Donut 2059.avif";

export const Data = [
  {
    id: 1,
    image: Donut2924,
    title: "View On OpenSea",
    description: "Donut 2924 - DonutShop",
    link: "https://opensea.io/assets/ethereum/0xca20f7279f7defd14e7524e609704ea2f436a539/2924",
  },
  {
    id: 2,
    image: Donut2608,
    title: "View On OpenSea",
    description: "Donut 2608 - DonutShop",
    link: "https://opensea.io/assets/ethereum/0xca20f7279f7defd14e7524e609704ea2f436a539/2608",
  },
  {
    id: 3,
    image: Donut2059,
    title: "View On OpenSea",
    description: "Donut 2059 - DonutShop",
    link: "https://opensea.io/assets/ethereum/0xca20f7279f7defd14e7524e609704ea2f436a539/2059",
  },
];
