import React, { useState } from "react";
import "./qualification.css";

const Qualification = () => {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <section className='qualification section'>
      <h2 className='section__title'>My Education and Exprience</h2>
      <span className='section__subtitle'>My Personal Journey So Far</span>

      <div className='qualification__container container'>
        <div className='qualification__tabs'>
          <div
            className={
              toggleState === 1
                ? "qualification__button qualification__active button--flex"
                : "qualification__button button--flex"
            }
            onClick={() => toggleTab(1)}
          >
            <i className='uil uil-graduation-cap qualification__icon'></i>
            Education
          </div>

          <div
            className={
              toggleState === 2
                ? "qualification__button qualification__active button--flex"
                : "qualification__button button--flex"
            }
            onClick={() => toggleTab(2)}
          >
            <i className='uil uil-briefcase-alt qualification__icon'></i>
            Experience
          </div>
        </div>

        <div className='qualification__sections'>
          <div
            className={
              toggleState === 1
                ? "qualification__content qualification__content-active"
                : "qualification__content"
            }
          >
            <div className='qualification__data'>
              <div>
                <h3 className='qualification__title'>Student</h3>
                <span className='qualification__subtitle'>
                  UT Dallas <br />
                  <b>- Richardson TX</b>
                </span>
                <div className='qualification__calender'>
                  <i className='uil uil-calendar-alt'></i> 2021 - Present
                </div>
              </div>

              <div>
                <span className='qualification__rounder'></span>
                <span className='qualification__line'></span>
              </div>
            </div>

            <div className='qualification__data'>
              <div></div>

              <div>
                <span className='qualification__rounder'></span>
                <span className='qualification__line'></span>
              </div>

              <div>
                <h3 className='qualification__title'>Student</h3>
                <span className='qualification__subtitle'>
                  Heritage High School <br /> <b> - Frisco TX</b>
                </span>
                <div className='qualification__calender'>
                  <i className='uil uil-calendar-alt'></i> 2018 - 2021
                </div>
              </div>
            </div>

            <div className='qualification__data'>
              <div>
                <h3 className='qualification__title'>Student</h3>
                <span className='qualification__subtitle'>
                  Liberty High School <br /> <b> - Frisco TX</b>
                </span>
                <div className='qualification__calender'>
                  <i className='uil uil-calendar-alt'></i> 2017 - 2018
                </div>
              </div>

              <div>
                <span className='qualification__rounder'></span>
                <span className='qualification__line'></span>
              </div>
            </div>

            <div className='qualification__data'>
              <div></div>

              <div>
                <span className='qualification__rounder'></span>
              </div>

              <div>
                <h3 className='qualification__title'>Student</h3>
                <span className='qualification__subtitle'>
                  Millard North High School <b>- Omaha NE</b>
                </span>
                <div className='qualification__calender'>
                  <i className='uil uil-calendar-alt'></i> 2017
                </div>
              </div>
            </div>
          </div>

          <div
            className={
              toggleState === 2
                ? "qualification__content qualification__content-active"
                : "qualification__content"
            }
          >
            <div className='qualification__data'>
              <div></div>

              <div>
                <span className='qualification__rounder'></span>
                <span className='qualification__line'></span>
              </div>

              <div>
                <h3 className='qualification__title'>Software Engineer</h3>
                <span className='qualification__subtitle'>
                  Blackstone LaunchPad: UTD
                  <b> - Richardson TX</b>
                </span>
                <div className='qualification__calender'>
                  <i className='uil uil-calendar-alt'></i> Jun 2023 -{" "}
                  <b>Present</b>
                </div>
              </div>
            </div>

            <div className='qualification__data'>
              <div>
                <h3 className='qualification__title'>
                  Technology Analyst & Business Operation Intern
                </h3>
                <span className='qualification__subtitle'>
                  Oigetit Fake News Filter <br /> <b>- Sunnyvale CA</b>
                </span>
                <div className='qualification__calender'>
                  <i className='uil uil-calendar-alt'></i> Sep 2021 - May 2022
                </div>
              </div>

              <div>
                <span className='qualification__rounder'></span>
                <span className='qualification__line'></span>
              </div>
            </div>

            <div className='qualification__data'>
              <div></div>

              <div>
                <span className='qualification__rounder'></span>
                <span className='qualification__line'></span>
              </div>

              <div>
                <h3 className='qualification__title'>
                  Founder & Software Engineer
                </h3>
                <span className='qualification__subtitle'>
                  ALLIN NFC <b>- Dallas TX</b>
                </span>
                <div className='qualification__calender'>
                  <i className='uil uil-calendar-alt'></i> Jun 2020 - Feb 2022
                </div>
              </div>
            </div>

            <div className='qualification__data'>
              <div>
                <h3 className='qualification__title'>
                  Entrepreneurship Intern
                </h3>
                <span className='qualification__subtitle'>
                  iStart Valley <br />
                  <b>- Dallas TX</b>
                </span>
                <div className='qualification__calender'>
                  <i className='uil uil-calendar-alt'></i> Jun 2020 - Nov 2020
                </div>
              </div>

              <div>
                <span className='qualification__rounder'></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Qualification;
