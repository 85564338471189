import React from "react";

const Info = () => {
  return (
    <div className='about__info grid'>
      <div className='about__box'>
        <i class='bx bx-award about__icon'></i>

        <h3 className='about__title'>Entrepreneur</h3>
        <span className='about__subtitle'>Founder ALLIN NFC</span>
      </div>

      <div className='about__box'>
        <i class='bx bx-briefcase-alt about__icon'></i>

        <h3 className='about__title'>Aspiring</h3>
        <span className='about__subtitle'>Software Engineer</span>
      </div>

      <div className='about__box'>
        <i class='uil uil-graduation-cap about__icon'></i>

        <h3 className='about__title'>Student</h3>
        <span className='about__subtitle'>Junior at UTD</span>
      </div>
    </div>
  );
};

export default Info;
